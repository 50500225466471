.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader__spinner {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid var(--secondSkin);
  border-right-color: var(--brown);
  animation: l2 1s infinite linear;
}

@keyframes l2 {
  to {
    transform: rotate(1turn)
  }
}