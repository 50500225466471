.sections__padding {
  padding: 60px 0;

  @media screen and (max-width: 1024px) {
    padding: 40px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 30px 0;
  }
}
