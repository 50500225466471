:root {
  --white: #fff;
  --black: #000;
  --grey: #737373;
  --brown: #a86464;
  --skin: #fff8f9;
  --green: #379237;
  --red: #e61b1b;
  --secondSkin: #e6e6e6;
  --secondGrey: #999999;
  --lightRed: #ffe9e9;
  --lightBrown: #f6f0f0;
  --lightSkin: #f9f2ee;
  --lightGrey: #f5f5f5;
  --thirdGrey: #F9F9F9;
}